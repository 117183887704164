.header {
    width: 100%;
    background-color: #205081;
    border-bottom: 1px solid #fff;

    a {
        display: inline-block;
        padding: 12px 12px;
        font-size: 20px;
        line-height: 24px;
        min-height: 56px;
        position: relative;
        color: #fff;
        text-decoration: none;
        box-sizing: border-box;
        outline: none;

        img {
            vertical-align: middle;
        }

        span {
            display: inline-block;
            position: relative;
            font-weight: 700;
            text-transform: uppercase;
            top: 3px;
        }
    }
}
