.footer {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    background: #fff;
    margin-top: 12px;
    border-top: 1px solid #d9d9d9;

    a {
        display: block;
        width: 100%;
        padding: 16px;
        color: #444;
        font-size: 14px;
        text-decoration: none;

        .link {
            color: #205081;
            text-decoration: underline;
        }

        &:hover, &:focus {
            .link {
                text-decoration: none;
            }
        }

        &:focus {
            background-color: #205081;
            color: #fff;

            .link {
                color: #fff;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .footer {
        position: relative;
        bottom: auto;
    }
}
