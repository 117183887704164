.cta {
    position: relative;
    font-weight: bold;
    text-decoration: none;
    display: block;
    padding: 12px;
    transition: all 80ms ease-in;
    cursor: pointer;
    outline: none;
    margin: 0;
}

.secondary {
    color: #205081;
    background: transparent;

    &:hover,
    &:focus {
        background: #205081;
        color: #fff;
    }
}

.primary {
    width: 100%;
}

.primary,
.pill {
    padding: 16px 12px;
    background: #336fad;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    border: 0;
    border-radius: 50vh;
    text-align: center;

    &:disabled {
        background-image: linear-gradient(135deg, #205081 25%, #154370 25%, #154370 50%, #205081 50%, #205081 75%, #154370 75%, #154370 100%);
        background-size: 10px 10px;
    }

    &:focus,
    &:hover {
        background-color: #205081;
    }

    &.icon {
        font-weight: 400;
    }
}

.pill {
    padding: 12px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.border,
.border-inverted {
    display: inline-block;
    background: #d6e9fb;
    border: 1px solid #205081;
    min-width: 60px;
    padding: 8px 12px;
    color: #205081;
    margin: 0 6px 6px 0;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
}

.border-inverted {
    background: #205081;
    color: #d6e9fb;
}

.border {
    &:focus,
    &:hover {
        background-color: #d6e9fb;
    }
}

.icon {
    width: auto;
    padding: 8px 20px 8px 36px;

    &:before {
        content: '';
        background-color: #fff;
        height: 12px;
        width: 12px;
        position: absolute;
        top: 50%;
        margin-top: -6px;
        left: 16px;
    }

    &.secondary {
        padding: 12px 20px 12px 36px;

        &:before {
            background-color: #205081;
        }

        &:hover,
        &:focus {
            &:before {
                background-color: #fff;
            }
        }
    }
}

$icons: (
    "trophy": "trophy-solid",
    "stat": "stats-solid",
    "compare": "compare-solid",
    "form": "chart-bar-regular",
    "players": "user-solid",
    "teams": "users-solid"
);

@each $name, $icon in $icons {
    .#{$name}:before {
        mask: url(./icons/#{$icon}.svg) no-repeat 50% 50%;
    }
}
