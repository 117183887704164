.text {
    background: #fff;
    border: 2px solid #e6e6e6;
    border-radius: 3px;
    font-size: 16px;
    padding: 8px;
    transition: all 100ms ease-in;
    width: 100%;
    position: relative;
    z-index: 2;

    &:focus {
        border-color: #205081;
        outline: none;
    }

    &.large {
        font-size: 18px;
        padding: 12px 8px;
    }
}
