.error-message {

    .content {
        background-color: #fff;
        padding: 12px;
    }

    a {
        color: #205081;
    }

    p {
        margin-bottom: 12px;
    }

    .message {
        font-size: 28px;
        line-height: 1.3em;
        margin-bottom: 20px;
    }

    .code {
        font-size: 12px;
    }

    .search {
        max-width: 500px;
        margin: 0 auto;
        margin-top: 30px;
    }
}

@media screen and (min-width: 768px) {
    .error-message {
        margin: 0 auto;
        padding: 0 12px;
        width: 900px;
        .content {
            padding: 20px 20px 40px 20px;
        }
    }

    :global(.page) {
        .error-message h1 {
            margin: 12px 0;
        }
    }
}
