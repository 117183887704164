.modal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    background-color: #eceae2;
    display: flex;
    flex-direction: column;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
}

.header {
    display: grid;
    padding: 16px 12px;
    grid-template-columns: 4fr 1fr;
    width: 100%;
    background: #fff;
    color: #205081;
    border-bottom: 1px solid #d9d9d9;
    position: relative;

    h1 {
        font-size: 24px;
    }

    span {
        font-size: 14px;
        color: #444;
        display: block;
        font-weight: normal;
    }

    button {
        background-color: transparent;
        border: none;
        width: 48px;
        height: 100%;
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;

        img {
            height: 24px;
            filter: invert(0.5);
            transition: filter 160ms ease;
        }

        &:global(.active) img,
        &:hover img {
            filter: invert(42%) sepia(18%) saturate(1861%) hue-rotate(170deg) brightness(89%) contrast(88%);
        }

        &:focus {
            outline: none;
            background: #205081;
            img {
                filter: invert(99%) sepia(2%) saturate(221%) hue-rotate(210deg) brightness(115%) contrast(100%);
            }
        }
    }
}

.content {
    padding: 12px;
}

.stats {
    .content {
        background: #fff;
        height: 100%;
        overflow-y: scroll;
        padding: 0;
    }
}

.loading {
    .header {
        border: 0;

        button {
            display: none;
        }
    }

    :global {
        .slider {
            z-index: 1;
            top: 0;
        }
    }
}

:global {
    .ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 160ms ease-in;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

@media screen and (min-width: 600px) {
    .modal {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 600px;
        height: auto;
        max-height: 90%;
        right: auto;
        bottom: auto;
        outline: none;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
        border-radius: 10px;

        &.stats {
            top: 20%;
            left: 50%;
            transform: translateY(-20%) translateX(-50%);
            width: 70%;
        }
    }

    .header {
        border-radius: 10px 10px 0 0;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99;

        span {
            display: inline-block;
            padding-left: 4px;
        }
    }

    .content {
        min-height: 400px;
        border-radius: 0 0 10px 10px;
        position: relative;
    }

    .loading {
        height: 90%;

        :global {
            .slider {
                top: 62px;
            }
        }
    }
}
