.menu {
    background: #f9f9f9;
    border-top: 3px solid #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    word-break: break-all;
    z-index: 4;
    filter: drop-shadow(0 0 2px #d9d9d9);

    li {
        text-align: center;

        &:first-of-type {
            display: none;
        }
    }

    a, button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        min-width: 48px;
        width: auto;
        height: 64px;
        margin: 0 auto;
        font-size: 12px;
        text-decoration: none;
        color: #fff;
        line-height: 1em;
        cursor: pointer;
        outline: none;
        border-bottom: 3px solid transparent;
        transition: all 40ms ease-in;

        &:global(.active) img,
        &:hover img,
        &:focus img {
            filter: invert(42%) sepia(18%) saturate(1861%) hue-rotate(170deg) brightness(89%) contrast(88%);
        }
        &:global(.active),
        &:hover,
        &:focus {
            border-bottom: 3px solid #336fad;

            span {
                color: #336fad;
            }
        }
    }

    button {
        width: 100%;
    }

    img {
        height: 20px;
        width: 20px;
        padding-top: 2px;
        filter: invert(0.5);
        transition: filter 160ms ease-in;
    }

    span {
        color: #333;
        margin-top: 8px;
        transition: color 160ms ease-in;
        line-height: 1em;
        font-size: 11px;
    }
}

@media screen and (min-width: 768px) {
    .menu {
        background: #205081;
        border-top-width: 0;
        border-bottom: 1px solid #fff;
        height: 100%;
        position: static;
        padding-right: 60px;
        grid-template-columns: repeat(5, 1fr);
        filter: none;

        a, button {
            height: 100%;
            transition: background-color 160ms ease-in;

            &:global(.active) img,
            &:hover img,
            &:focus img {
                filter: invert(1);
            }

            &:global(.active),
            &:focus,
            &:hover {
                background-color: #336fad;
                span {
                    color: #fff;
                }
            }

            &:focus,
            &:hover {
                border-bottom: 2px solid #fff;
            }
        }

        li:first-of-type {
            display: block;
        }

        img {
            height: 20px;
            width: 20px;
            filter: invert(1);
        }

        span {
            margin-top: 4px;
            display: block;
            font-size: 12px;
            color: #fff;
        }
    }
}
