html, body {
    background: #eceae2;
    min-height: 100%;
}

body {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    font-family: system, -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, Lucida Grande, sans-serif;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
li,
button {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

*,
::after,
::before {
    box-sizing: border-box;
}

input {
    -webkit-appearance: none;
    opacity: 1;
}

#root {
    width: 100%;
    height: 100vh;
    padding-bottom: 70px;
    position: absolute;
    overflow: auto;
    overflow-y: scroll;
}

.app {
    height: auto;
    position: relative;
}

.page {
    padding: 8px 0;
    padding-bottom: 70px;
    position: relative;

    h1 {
        margin: 12px;
        padding-bottom: 4px;
        font-size: 32px;
        line-height: 48px;
        font-weight: 500;

        span {
            display: block;
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
        }
    }
}

:global(.load-end) {
    text-align: center;
    padding: 0;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #444;
}

.slider {
    position: fixed;
    width: 100%;
    top: 57px;
    left: 0;
    height: 3px;
    overflow-x: hidden;
}

.line {
    position: absolute;
    opacity: 0.4;
    background: #1e88e5;
    width: 150%;
    height: 4px;
}

.subline {
    position: absolute;
    background: #1e88e5;
    height: 4px;
}

.inc {
    animation: increase 2s infinite;
}

.dec {
    animation: decrease 2s 0.5s infinite;
}

@keyframes contentLoad {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes increase {
    from {
        left: -5%;
        width: 5%;
    }
    to {
        left: 130%;
        width: 100%;
    }
}

@keyframes decrease {
    from {
        left: -80%;
        width: 80%;
    }
    to {
        left: 110%;
        width: 10%;
    }
}

@media screen and (min-width: 768px) {
    #root {
        padding-bottom: 12px;
    }

    .app {
        display: grid;
        grid-template-columns: 3fr 6fr;
    }

    .page {
        grid-column: 1/3;
        padding-bottom: 0;

        h1 span {
            display: inline-block;
            padding-left: 8px;
        }
    }

    :global(.load-end) {
        padding: 20px 0;
        margin: 0;
    }
}
