
.selector {
    position: absolute;
    top: 8px;
    right: 6px;
    width: 40px;
    height: 40px;
    z-index: 5;
    text-align: center;
    background: transparent;
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;
    outline: none;

    span {
        margin-left: 3px;
    }

    &:active, &:focus, &:hover {
        background-color: #336fad;
    }

    &:focus {
        border-color: #fff;
    }
}

@media screen and (min-width: 768px) {
    .selector {
        right: 12px;
    }
}
