.options {
    font-size: 18px;
    line-height: 20px;
    background: #fff;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -12px;
    box-sizing: content-box;

    li {
        border-bottom: 1px solid #e6e6e6;

        &.selected {
            background-color: #e1efff;
        }

        a {
            padding: 20px 12px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            color: #333;

            &:focus {
                outline: none;
                background: #205081;
                color: #fff;
            }
        }
    }
}
