.flag {
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #e6e6e6;
    box-sizing: border-box;
}
