.list {
    background: #fff;
    font-size: 13px;
    border: 2px solid #205081;
    margin-top: -3px;
    padding-top: 2px;
    border-top: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
    overflow: scroll;
    max-height: 206px;
    border-radius: 0 0 3px 3px;

    li {
        padding: 8px;
        border-bottom: 1px solid #e6e6e6;
        color: #205081;
        transition: all 160ms ease-in;
        outline: none;
        position: relative;

        &:last-of-type {
            border-radius: 0 0 3px 3px;
        }

        &:hover,
        &:focus {
            background: #205081;
            color: #fff;
        }

        &:before {
            content: '';
            background-color: #205081;
            height: 12px;
            width: 12px;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            right: 16px;
        }
    }

    h3 {
        display: inline-block;
        padding-right: 6px;
        font-size: 14px;
    }
}

$icons: (
    "player": "user-solid",
    "team": "users-solid",
    "tournament": "trophy-solid"
);

@each $name, $icon in $icons {
    .#{$name}:before {
        mask: url(./icons/#{$icon}.svg) no-repeat 50% 50%;
    }
}
