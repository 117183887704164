.search {
    background: #fbfbfb;
    margin-bottom: 12px;
    position: relative;

    .modal & {
        margin-bottom: 20px;
        background: transparent;
    }
}

.modal {
    padding: 20px 8px;
}

@media screen and (min-width: 768px) {
    .home {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 20px;
    }
    .search {
        margin: 0;
    }

    .modal {
        min-height: 300px;
    }
}
